import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query"
import { AxiosError, AxiosResponse } from "axios"

import { API_DEFAULT } from "src/constants/minutApi"
import { HomeGroupRole } from "src/data/homeGroups/types/homeGroupTypes"
import { orgsKeys } from "src/data/organizations/queries/organizationQueryCache"
import {
  HomeRole,
  TOrganizationRole,
} from "src/data/organizations/types/organizationMemberTypes"
import {
  InvitationType,
  IOrganizationInvitationMembersFilter,
  IOrganizationInvitationPost,
  IOrganizationInvitationUserFilter,
  TInvitationInfo,
  TOrganizationInvitation,
  TOrganizationInvitationsResponse,
} from "src/data/organizations/types/organizationTypes"
import { userKeys } from "src/data/user/queries/userQueryCache"
import { OrganizationParams } from "src/router/organizationRoutes"
import { Routes } from "src/router/routes"
import { minutApiHttpClient } from "src/utils/minutApiHttpClient"

/**
 * Returns a list of an organizations members
 *
 * https://api.staging.minut.com/latest/docs/internal#tag/Organization-Invitations/paths/~1organizations~1{organization_id}~1invitations/get
 */
export function useFetchMemberInvites({
  orgId,
  filter,
  options,
}: {
  orgId: string
  filter: IOrganizationInvitationMembersFilter
  options?: UseQueryOptions<
    TOrganizationInvitationsResponse,
    AxiosError,
    TOrganizationInvitationsResponse,
    ReturnType<typeof orgsKeys.invitations>
  >
}) {
  async function getInvites({
    orgId,
    filter,
  }: {
    orgId: string
    filter: IOrganizationInvitationMembersFilter
  }) {
    const response = await minutApiHttpClient.get(
      `${API_DEFAULT}/organizations/${orgId}/invitations`,
      { params: filter }
    )
    return response.data
  }

  return useQuery(
    orgsKeys.invitations(orgId),
    () => getInvites({ orgId, filter }),
    {
      keepPreviousData: true,
      ...options,
    }
  )
}

export function usePostMemberInvite() {
  const cache = useQueryClient()

  async function postMemberInvite({
    orgId,
    body,
  }: {
    orgId: string
    body: Pick<IOrganizationInvitationPost, "email" | "role">
  }) {
    const response = await postOrganizationInvite({
      orgId,
      body: {
        ...body,
        invitation_target_id: orgId,
        type: InvitationType.ORGANIZATION,
      },
    })
    return response.data
  }

  return useMutation(postMemberInvite, {
    onSuccess: async (invite, vars) => {
      await cache.invalidateQueries(orgsKeys.invitations(vars.orgId))
    },
  })
}

interface IOrganizationPostHomeMemberInvite {
  orgId: string
  homeId: string
  body: Pick<IOrganizationInvitationPost, "email" | "role">
}
export function usePostHomeMemberInvite() {
  const cache = useQueryClient()

  async function postHomeMemberInvite({
    orgId,
    homeId,
    body,
  }: IOrganizationPostHomeMemberInvite) {
    const response = await postOrganizationInvite({
      orgId,
      body: {
        ...body,
        invitation_target_id: homeId,
        type: InvitationType.HOME,
      },
    })
    return response.data
  }

  return useMutation<
    TOrganizationInvitation,
    AxiosError,
    IOrganizationPostHomeMemberInvite
  >(postHomeMemberInvite, {
    onSuccess: async (invite, vars) => {
      await cache.invalidateQueries(orgsKeys.invitations(vars.orgId))
    },
  })
}

/**
 * Invite a user to an organization
 *
 * https://api.staging.minut.com/latest/docs/internal#tag/Organization-Invitations/paths/~1organizations~1{organization_id}~1invitations/post
 *
 * Responses
 * 200 OK
 * 403 Forbidden
 * 409 Duplicate email
 */
export async function postOrganizationInvite({
  orgId,
  body,
}: {
  orgId: string
  body: Omit<IOrganizationInvitationPost, "accept_url">
}): Promise<
  AxiosResponse<TOrganizationInvitation, IOrganizationInvitationPost>
> {
  const accept_url = `${window.location.origin}${
    Routes.Signup.location().pathname
  }?${OrganizationParams.INVITATION_CODE}=`
  const fullBody: IOrganizationInvitationPost = { ...body, accept_url }

  return await minutApiHttpClient.post<TOrganizationInvitation>(
    `${API_DEFAULT}/organizations/${orgId}/invitations`,
    fullBody
  )
}

/**
 * Modify an existing member invite
 *
 * https://api.staging.minut.com/latest/docs/internal#tag/Organization-Invitations/paths/~1organizations~1{organization_id}~1invitations~1{invitation_id}/patch
 *
 */
export function usePatchMemberInvite() {
  const cache = useQueryClient()

  async function patchInvite({
    orgId,
    invitationId,
    role,
  }: {
    orgId: string
    invitationId: string
    role: TOrganizationRole | HomeRole | HomeGroupRole
  }): Promise<AxiosResponse<TOrganizationInvitation>> {
    const response = await minutApiHttpClient.patch<TOrganizationInvitation>(
      `${API_DEFAULT}/organizations/${orgId}/invitations/${invitationId}`,
      { role }
    )
    return response
  }

  return useMutation(patchInvite, {
    onSuccess: (newInvitationsResponse, vars) => {
      const invitationId = newInvitationsResponse.data.id
      cache.setQueryData<TOrganizationInvitationsResponse>(
        orgsKeys.invitations(vars.orgId),
        (data): TOrganizationInvitationsResponse => {
          if (!data?.invitations) return { ...data }

          return {
            ...data,
            invitations: data.invitations.map((invite) => {
              if (invite.id === invitationId) {
                return newInvitationsResponse.data
              }
              return invite
            }),
          }
        }
      )
    },
  })
}

interface IDeleteMemberInvite {
  orgId: string
  invitationId: string
}
/**
 * Delete a member invite
 *
 * https://api.staging.minut.com/latest/docs/internal#tag/Organization-Invitations/paths/~1organizations~1{organization_id}~1invitations~1{invitation_id}/delete
 *
 * Responses
 * 204 No content
 * 403 Forbidden
 */
export function useDeleteMemberInvite() {
  const cache = useQueryClient()

  async function deleteMemberInvite({
    orgId,
    invitationId,
  }: IDeleteMemberInvite) {
    const response = await minutApiHttpClient.delete(
      `${API_DEFAULT}/organizations/${orgId}/invitations/${invitationId}`
    )
    return response
  }

  function updateQueryCache(props: IDeleteMemberInvite) {
    cache.setQueryData<TOrganizationInvitationsResponse>(
      orgsKeys.invitations(props.orgId),
      (data): TOrganizationInvitationsResponse => {
        if (!data?.invitations) return { ...data }

        return {
          ...data,
          invitations: data.invitations.filter((invite) => {
            return invite.id !== props.invitationId
          }),
        }
      }
    )
  }

  return useMutation(deleteMemberInvite, {
    onSuccess: (_, vars) => {
      updateQueryCache(vars)
    },
  })
}

/**
 * Accept an invite
 *
 * https://api.staging.minut.com/latest/docs/internal#tag/Organization-Invitations/paths/~1organizations~1{organization_id}~1invitations/post
 *
 * Responses
 * 200 OK
 * 403 Forbidden
 */
interface IPatchOrganization {
  inviteCode: string
}
export function usePatchOrganizationInvite() {
  const cache = useQueryClient()

  async function patchOrganizationInvite({ inviteCode }: IPatchOrganization) {
    const response = await minutApiHttpClient.patch<TOrganizationInvitation>(
      `${API_DEFAULT}/invitations`,
      {
        invitation_code: inviteCode,
      }
    )
    return response.data
  }

  return useMutation<
    TOrganizationInvitation,
    AxiosError<{
      error_key: "invite_already_accepted"
      message: string
    }>,
    IPatchOrganization
  >(patchOrganizationInvite, {
    onSuccess: async () => {
      return await Promise.all([
        // Invalidate org list cache since we now will have one additional organization
        cache.invalidateQueries(orgsKeys.orgList()),
      ])
    },
  })
}

/**
 * Returns a list of all the organization invitations the user has received
 *
 * https://api.staging.minut.com/latest/docs/internal#get-/users/-user_id-/invitations
 */
export function useFetchUserInvites({
  userId,
  filter,
  options,
}: {
  userId: string
  filter: IOrganizationInvitationUserFilter
  options?: UseQueryOptions<
    TOrganizationInvitation[],
    AxiosError,
    TOrganizationInvitation[],
    ReturnType<typeof userKeys.invitations>
  >
}) {
  async function fetchUserInvites({
    userId,
    filter,
  }: {
    userId: string
    filter: IOrganizationInvitationUserFilter
  }) {
    const response: AxiosResponse<{
      invitations: TOrganizationInvitation[]
    }> = await minutApiHttpClient.get(
      `${API_DEFAULT}/users/${userId}/invitations`,
      { params: filter }
    )
    return response.data.invitations
  }

  return useQuery(
    userKeys.invitations(userId),
    () => fetchUserInvites({ userId, filter }),
    {
      keepPreviousData: true,
      ...options,
    }
  )
}

export function useFetchUserInvitation({
  userId,
  invitationCode,
  options,
}: {
  userId: string
  invitationCode: string
  options?: UseQueryOptions<
    TOrganizationInvitation,
    AxiosError,
    TOrganizationInvitation,
    ReturnType<typeof userKeys.invitation>
  >
}) {
  async function fetchUserInvitation({
    userId,
    invitationCode,
  }: {
    userId: string
    invitationCode: string
  }) {
    const response: AxiosResponse<TOrganizationInvitation> =
      await minutApiHttpClient.get(
        `${API_DEFAULT}/users/${userId}/invitations/${invitationCode}`
      )
    return response.data
  }

  return useQuery(
    userKeys.invitation(userId, invitationCode),
    () => fetchUserInvitation({ userId, invitationCode }),
    {
      keepPreviousData: true,
      ...options,
    }
  )
}

export function useFetchInvitationInfo({
  inviteCode,
  options,
}: {
  inviteCode: string
  options?: UseQueryOptions<
    TInvitationInfo,
    AxiosError,
    TInvitationInfo,
    ReturnType<typeof userKeys.invitationInfo>
  >
}) {
  async function fetchInvitationInfo({ inviteCode }: { inviteCode: string }) {
    const response: AxiosResponse<TInvitationInfo> =
      await minutApiHttpClient.get(`${API_DEFAULT}/invitations`, {
        params: { invitation_code: inviteCode },
      })
    return response.data
  }

  return useQuery(
    userKeys.invitationInfo(inviteCode),
    () => fetchInvitationInfo({ inviteCode }),
    {
      keepPreviousData: true,
      ...options,
    }
  )
}
